<template>
  <div>
      <v-row>
          <v-col cols="2">
              <v-card flat>
                  <v-card-text>
                      <v-row justify="center">
                          <v-date-picker
                                  v-model="dates"
                                  locale="ru"
                                  full-width
                                  range
                                  first-day-of-week="1"
                                  no-title
                          ></v-date-picker>
                      </v-row>


                      <v-row
                              align="center"
                              justify="space-around"
                      >
                          <v-btn
                                  depressed
                                  @click="resetForm"
                                  class="mb-3"
                          >
                              Сбросить
                          </v-btn>
                          <v-btn
                                  depressed
                                  color="primary"
                                  @click="getDeparture"
                                  class="mb-3"
                          >
                              Применить
                          </v-btn>
                      </v-row>
                  </v-card-text>
              </v-card>

          </v-col>
          <v-col cols="10">
              <v-calendar
                        type="custom-daily"
                      weekdays="1, 2, 3, 4, 5, 6, 0"
                      :events="events"
                      :event-color="getEventColor"
                      locale="ru"
                      color="primary"
                      :start="startDate"
                      :end="endDate"
                        @click:event="goToOrder"
                        first-interval="7"
                        interval-count="13"
                        interval-height="80"
                        :short-intervals="shortintervals"
                        :interval-style="intervalStyle"
              >

              </v-calendar>
          </v-col>
      </v-row>
  </div>
</template>

<script>
    import CalendarDataService from "@/services/CalendarDataService";

    //Это раскрашивает выходные.
    //Фиг понял как это работает
    //https://codepen.io/sneaky666/pen/JgvGEB?editors=1010
    const stylings = {
        workday (interval) {
            const inactive = interval.weekday === 0 ||
                interval.weekday === 6
            const startOfHour = interval.minute === 0
            const dark = this.dark
            const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

            return {
                backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(255,0,0,0.06)') : undefined,
                borderTop: startOfHour ? undefined : '1px dashed ' + mid,
            }
        }
    }

export default {
  name: 'Calendar',
  components: {
    // CustomersTable
  },

    data: function() {
        return {
            dates: [new Date().toISOString().substr(0, 10), this.addDays(6).toISOString().substr(0, 10)],
            startDate: new Date().toISOString().substr(0, 10),
            endDate: this.addDays(6).toISOString().substr(0, 10),
            departure: [],
            events: [],
            currDate: new Date(), //Текущая дата
            shortintervals: false,
        }
    },
    mounted() {
        //Запрашиваем данные с сервера
        this.getDeparture()
    },
    computed: {
        intervalStyle () {
            return stylings['workday'].bind(this)
        },
    },
    methods: {
        // intervalStyle () {
        //
        //     return { backgroundColor:'rgba(0,0,0,0.5)'}
        // },
        getEventColor(event) {
            return event.color
        },
        addDays(days) {
            let result = new Date();
            result.setDate(result.getDate() + days);
            return result;
        },
        getDeparture() {

            this.departure = []
            this.events = []

            if (this.dates.length === 1) {
                this.startDate = this.dates[0]
                this.endDate = this.dates[0]
            }
            if (this.dates.length === 2) {
                let arrDates=[];
                arrDates.push(new Date(this.dates[0]))
                arrDates.push(new Date(this.dates[1]))

                var maxDate=new Date(Math.max.apply(null, arrDates));
                var minDate=new Date(Math.min.apply(null, arrDates));

                this.startDate = minDate.toISOString().substr(0, 10)
                this.endDate = maxDate.toISOString().substr(0, 10)
            }

            CalendarDataService.getDeparture(this.startDate , this.endDate)
                .then(response => {
                    this.departure= response.data
                    this.fillCalendar(this.departure.length)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        fillCalendar(length){
            var index;
            for (index = 0; index < length; ++index) {
                let dateDeparture = new Date(this.departure[index].datedeparture.date) //Дата выезда
                let timeDeparture = this.departure[index].timedeparture.split(':')[0] //Время выезда (часы)

                let startDateDeparture = dateDeparture.setHours(dateDeparture.getHours() + timeDeparture)
                let endDateDeparture = startDateDeparture + 7200000 //Прибавляем 2 часа (миллисекунды)

                this.events.push({
                    name: '[' + this.departure[index].contractnumber + ']  ' +  this.departure[index].estatecod.fulladdress,
                    start: startDateDeparture,
                    end: endDateDeparture,
                    color: 'indigo lighten-1',
                    timed: 1,
                    orderid: this.departure[index].orderid
                })
            }
        },
        resetForm() {
            this.dates = [new Date().toISOString().substr(0, 10), this.addDays(6).toISOString().substr(0, 10)]
            this.getDeparture()  // Если хотим сразу обновить календарь
        },
        goToOrder({event}) {
            //alert(event.orderid)
            this.$router.push({name: 'EditContract', params: { id: event.orderid }})
        }
    }
}
</script>

<style>
    .v-application .pl-1  {
        white-space: pre-line;
        font-weight: lighter;
    }
</style>

