<template>
    <div class="text-right">
        <v-dialog
                v-model="isModalOpen"
                persistent
                width="600"
        >
            <v-card>
                <v-card-title class="headline">
                    Новая номенклатура
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="servicename"
                                            label="Наименование"
                                            :rules="externalRules.requiredRules"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                   <v-autocomplete
                                        v-model="measureSelect"
                                        :items="measureItems"
                                        item-text="measurefullname"
                                        item-value="measureid"
                                        label="Единица измерения"
                                        :rules="externalRules.requiredRules"
                                    ></v-autocomplete>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="servicedefaultprice"
                                            label="Стоимость по умолчанию"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                            v-model="servicedescription"
                                            label="Описание"
                                            rows="3"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                            class="mr-3 mt-4"
                                            color="primary"
                                            depressed
                                            :loading="loading"
                                            @click="submitForm"
                                    >
                                        Создать
                                    </v-btn>
                                    <v-btn
                                            class="mt-4"
                                            color="primary"
                                            outlined
                                            @click="closeDialog"
                                            depressed
                                    >
                                        Отмена
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import MeasureTableDataService from "@/services/MeasureTableDataService";
    import ServiceViewDataService from "@/services/ServiceViewDataService";
    import * as ExternalRules from "@/rules/ExternalRules";

    export default {
        name: "AddNomenclatureForm",
        data () {
            return {
                loading: false,
                servicename: null,
                servicedefaultprice: null,
                servicedescription: null,
                measureSelect: null,
                measureItems: [],
                validForm: true,
                externalRules: ExternalRules,
            }
        },
        computed: {
            // Проверка состояния диалога открыт/закрыт в VUEX
            isModalOpen() {
                return this.$store.getters.isAddNomenclatureDialogOpen;
            }
        },

        watch: {
            isModalOpen(newValue) {
                if (newValue) {
                    //this.resetForm() // Сбрасываем форму при открытии диалога
                    this.getMeasure() //Запрос единиц измерения
                }
            }
        },

        methods: {

            resetForm() {
                // this.addressGroup = '1',
                // this.loading = false,
                // this.kadnumber = null,
                // this.adressregion = null,
                // this.adressdistrict = null,
                // this.adresssettlementpoint = null,
                // this.adressstreet = null,
                // this.adresshouse = null,
                // this.adressoptionalformat = null
            },

            //Пользовательское событие при успешном сохранении. Это событие слушаем в родительском компоненте
            //serviceid - id созданной номенклатуры
            createEvent(serviceid) {
                this.closeDialog()
                this.$emit('create-nomenclature-event', serviceid)
            },

            getMeasure() {
                MeasureTableDataService.find()
                    .then(response => {
                        this.measureItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            closeDialog() {
                this.$store.dispatch('closeAddNomenclatureDialog');
            },

            submitForm () {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        servicename: this.servicename,
                        servicedefaultprice: this.servicedefaultprice,
                        servicedescription: this.servicedescription,
                        servicemeasurekod: this.measureSelect
                    }

                    ServiceViewDataService.create(data)
                        .then(response => {
                            console.log(response.data)
                            //this.$router.push({ name: 'EditRealestates', params: { id : response.data.id }})
                            this.createEvent(response.data.id);
                        })
                        .catch(error => {
                            console.log(error);
                        });

                }

            }
        }
    }
</script>

<style scoped>

</style>