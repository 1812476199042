import http from "../http-common"
import authHeader from './auth-header';
import QueryString from "qs";

class ServiceViewDataService {

    //Запрос (поиск) записей
    find(params) {
        return http.get(`/serviceview`,{ params: params, paramsSerializer: params => {return QueryString.stringify(params, {arrayFormat: 'comma'})}, headers: authHeader() });
    }

    //Создание записи
    create(data) {
        return http.post("/serviceview", data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/serviceview/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`serviceview/${id}`, data, { headers: authHeader() })
    }
}

export default new ServiceViewDataService()