var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddDocumentForm',{attrs:{"copydocumentid":_vm.copydocumentid},on:{"create-document-event":_vm.handleCreateDocumentEvent}}),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-size":"25px","font-weight":"300"}},[_vm._v("Расходы")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.addDocument}},[_vm._v(" Создать ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Контрагент","placeholder":"Введите данные для поиска"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.get($event)}},model:{value:(_vm.documentCustomer),callback:function ($$v) {_vm.documentCustomer=$$v},expression:"documentCustomer"}}),_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"0"},attrs:{"cols":"6"}},[_c('vc-date-picker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.##.####'),expression:"'##.##.####'"}],ref:"startDate",attrs:{"label":"Дата документа","placeholder":"Начало","value":inputValue,"rules":_vm.externalRules.dateRules},on:{"click":function($event){return togglePopover()}},model:{value:(_vm.startDocumentDate),callback:function ($$v) {_vm.startDocumentDate=$$v},expression:"startDocumentDate"}})]}}]),model:{value:(_vm.startDocumentDate),callback:function ($$v) {_vm.startDocumentDate=$$v},expression:"startDocumentDate"}})],1),_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"0"},attrs:{"cols":"6"}},[_c('vc-date-picker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.##.####'),expression:"'##.##.####'"}],ref:"endDate",attrs:{"placeholder":"Окончание","value":inputValue,"rules":_vm.externalRules.dateRules},on:{"click":function($event){return togglePopover()}},model:{value:(_vm.endDocumentDate),callback:function ($$v) {_vm.endDocumentDate=$$v},expression:"endDocumentDate"}})]}}]),model:{value:(_vm.endDocumentDate),callback:function ($$v) {_vm.endDocumentDate=$$v},expression:"endDocumentDate"}})],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"0"}},[_c('v-select',{attrs:{"label":"Статья","items":_vm.accountingArr,"item-text":"name","item-value":"id","return-object":""},model:{value:(_vm.accountingSelect),callback:function ($$v) {_vm.accountingSelect=$$v},expression:"accountingSelect"}})],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"0"}},[_c('v-select',{attrs:{"label":"Направление деятельности","items":_vm.activityArr,"item-text":"name","item-value":"id","return-object":""},model:{value:(_vm.activitySelect),callback:function ($$v) {_vm.activitySelect=$$v},expression:"activitySelect"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Сбросить ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.get}},[_vm._v(" Применить ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"footer-props":{
                         itemsPerPageOptions: [ 50, 100, -1],
                         itemsPerPageAllText: 'Все',
                         itemsPerPageText: 'Строк на странице:'
                    },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"itemlink",attrs:{"to":{ name: 'EditDocument', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.date.date",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDocDate")(item.date.date))+" ")]}},{key:"item.sum",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.sum))+" ")]}},{key:"item.copydocument",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"size":"21"},on:{"click":function($event){return _vm.copydocument(item)}}},[_vm._v(" mdi-content-copy ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.auth.user.roles[0] == 'ROLE_SUPER_ADMIN'),expression:"$store.state.auth.user.roles[0] == 'ROLE_SUPER_ADMIN'"}]},[_c('th',[_vm._v("Всего:")]),_c('th'),_c('th',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(_vm.getDocumentsSumm())))])])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }