import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth'
import economic from './modules/economicIndicators'
import  task  from './modules/task'
import notice from "@/store/modules/notice";
import contract from "@/store/modules/contract";
import account from "@/store/modules/account";
import alert from "@/store/modules/alert";
import dialog from "@/store/modules/dialog";
import AddCustomerDialog from "@/store/modules/AddCustomerDialog";
import AddRealestateDialog from "@/store/modules/AddRealestateDialog";
import AddTaskDialog from "@/store/modules/AddTaskDialog";
import AddContractDialog from "@/store/modules/AddContractDialog";
import AddEmployeeDialog from "@/store/modules/AddEmployeeDialog";
import AddNomenclatureDialog from "@/store/modules/AddNomenclatureDialog";
import AddDocumentDialog from "@/store/modules/AddDocumentDialog";
import abilityPlugin from "@/store/modules/permissions";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [
        abilityPlugin
    ],
    modules: {
        auth,
        economic,
        task,
        notice,
        contract,
        account,
        alert,
        dialog,
        AddCustomerDialog,
        AddRealestateDialog,
        AddTaskDialog,
        AddContractDialog,
        AddEmployeeDialog,
        AddNomenclatureDialog,
        AddDocumentDialog,
        abilityPlugin
    },
})


export default store;
