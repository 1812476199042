<template>
  <div>

      <!--Сообщение об ошибке сохранения в БД-->
      <v-alert
              type="error"
              dismissible
              v-model="alertError"
              transition="scale-transition"
      >
          Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
      </v-alert>

      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

     <p style="font-size: 10px; margin: 0"> ArtCRM версия {{version}}</p>

      <div >
          <v-row>
              <v-col>
                  <v-card
                          outlined
                  >
                      <v-card-title>Договоры</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <v-col>
                                  <v-text-field
                                          v-model="maxResults"
                                          label="Максимальное количество записей в результатах поиска"
                                          style="width: 500px"
                                  ></v-text-field>
                              </v-col>
                          </v-row>

                          <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="save"
                          >
                              Сохранить
                          </v-btn>
                      </v-card-text>
                  </v-card>


                  <v-card
                          outlined
                          style="margin-top: 10px"
                  >
                      <v-card-title>Основное меню</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <v-col>
                                  <v-checkbox
                                          v-model="menu_right"
                                          label="Расположить справа"
                                          hide-details
                                  ></v-checkbox>
                                  <v-checkbox
                                          v-model="menu_expand_on_hover"
                                          label="Открывать при наведении"
                                          hide-details
                                  ></v-checkbox>
                              </v-col>
                          </v-row>
                      </v-card-text>
                  </v-card>
                  <v-card
                          outlined
                          style="margin-top: 10px"
                  >
                      <v-card-title>Справочники</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <v-col>
                                  <router-link class="itemlink" :to="{ name: 'Nomenclature'}">Номенклатура</router-link>
                              </v-col>
                          </v-row>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
      </div>






  </div>
</template>

<script>

import packageJson from '../../package.json';

export default {
  name: 'Settings',
    data: function() {
        return {
            alertError: false,
            snackbarSave: false,
            maxResults: 0,
            menu_right: false,
            menu_expand_on_hover: false,
            version: packageJson.version,
        }
    },
    mounted () {
        //read Localstorage
        if (localStorage.contractMaxResults) {
            this.maxResults = localStorage.contractMaxResults
        } else
        {
            this.maxResults = 50; //По умолчанию, если нет запичи в сторадже
        }


        if (localStorage.menu_right) {
            this.menu_right = JSON.parse(localStorage.getItem('menu_right'))
        } else
        {
            this.menu_right = false; //По умолчанию, если нет запичи в сторадже
        }

        if (localStorage.menu_expand_on_hover) {
            this.menu_expand_on_hover = JSON.parse(localStorage.getItem('menu_expand_on_hover'))
        } else
        {
            this.menu_expand_on_hover = false; //По умолчанию, если нет запичи в сторадже
        }


    },
    methods: {
      save() {
          //Save Localstorage
          localStorage.contractMaxResults = this.maxResults
          this.snackbarSave = true
      }
    },
    watch: {
        menu_right(newValue) {
            localStorage.menu_right = newValue;
        },
        menu_expand_on_hover(newValue) {
            localStorage.menu_expand_on_hover = newValue;
        }
    }
}
</script>
