<template>
    <div>
        <AddTaskForm
                :order_id="$route.params.id"
                @updateTasks="get"
        />

        <v-row>
            <v-col class="d-flex justify-end">
                <v-btn
                        color="primary"
                        depressed
                        @click="addTask"
                >
                    Создать
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="computedHeaders"
                        :items="items"
                        :loading="loading"
                        hide-default-footer
                >


                    <template v-slot:item.name="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditTask', params: { id: item.id }}"
                                :class="{done : item.status == 1}"
                        >
                            {{ item.name }}
                        </router-link>
                        <v-icon
                                color="red"
                                size="20"
                                v-if="item.importance == 1"
                        >
                            mdi-fire
                        </v-icon>
                    </template>


                    <template v-slot:item.end_date.date="{ item }">
                        <div :class="{enddeadline: getDeadlineStatus(item.end_date.date, item.status) == true}">
                            {{ formatDeadlineDate (item.end_date.date, item.status ) }}

                        </div>

                    </template>

                    <!--                    <template v-slot:item.status="{ item }">-->
                    <!--                        {{ item.status | formatStatusToText }}-->
                    <!--                    </template>-->

                    <template v-slot:item.status="{ item }">
                        <task-stepper :statusid="item.status" :taskid="item.id"/>
                    </template>


                    <template v-slot:item.create_employee_name="{ item }">
                        <v-avatar size="30" style="margin-right: 5px">
                            <img :src="`${avatars}${item.create_employee_avatar}`">
                        </v-avatar>
                        {{ item.create_employee_name}}
                    </template>

                    <template v-slot:item.responsible_employee_name="{ item }">
                        <v-avatar size="30" style="margin-right: 5px">
                            <img :src="`${avatars}${item.responsible_employee_avatar}`">
                        </v-avatar>
                        {{ item.responsible_employee_name}}
                    </template>


                    <template v-slot:item.create_date.date="{ item }">
                        {{ item.create_date.date | formatDate}}
                    </template>

                </v-data-table>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AddTaskForm from "@/components/AddTaskForm";
    import TaskDataService from "@/services/TaskDataService";
    import TaskStepper from "@/components/elements/TaskStepper";
    export default {
        name: "TasksTable",
        components: {AddTaskForm, TaskStepper},
        data() {
            return {
                loading: false,
                items: [],
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS
            }
        },
        computed: {
            computedHeaders () {
                const header =  [
                    {
                        text: 'Название',
                        value: 'name',
                        width: 400
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                        width: 100
                    },
                    {
                        text: 'Дата создания',
                        value: 'create_date.date',
                        width: 150
                    },
                    {
                        text: 'Срок',
                        value: 'end_date.date',
                        width: 150
                    },
                    {
                        text: 'Постановщик',
                        value: 'create_employee_name',
                        width: 250,
                    },
                    {
                        text: 'Ответственный',
                        value: 'responsible_employee_name',
                    },

                ]
                return header
            }
        },
        methods: {
            addTask() {
                this.$store.dispatch('showAddTaskDialog');
            },

            get() {
                this.loading = true
                TaskDataService.find(this.$store.state.auth.user.userid, '', this.$route.params.id)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            formatDeadlineDate(date, status) {
                //console.log(new Date(date), new Date())
                if (date)
                    if ((new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) && ( status == 0) ) {  //Для правильного сравнения дат обнуляем часы
                        let datediff = Math.floor((new Date(date) - new Date()) / (60 * 60 * 24 * 1000)) + 1  //Прибавляем +1( один день) чтобы день дедлайна был включительно
                        return datediff + " дн."
                    } else {
                        return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
                    }
                else
                    return 'без срока'
            },
            //True - задача просрочена
            getDeadlineStatus(date, status) {
                if (date)
                    if ((new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) && ( status == 0) ) {
                        return true
                    } else {
                        return false
                    }
                else
                    return 'без срока'
            },
        },

        mounted() {
            //Запрашиваем данные с сервера
            this.get()
        },

        filters: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            // formatStatusToText(status) {
            //     return status == 0 ? 'Ожидает выполнения' : 'Выполнено'
            // }
        },
    }


</script>

<style scoped>
    .reportwarning {
        /*background-color: #fbaeae;*/
        /*padding: 2px 5px 2px 5px;*/
        /*border-radius: 3px;*/
        color: red;
    }

    /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }

    .done {
        text-decoration: line-through  !important;
        color: #686868 !important;
    }
    .enddeadline {
        display: inline-block;
        background-color: red;
        padding: 2px 5px;
        border-radius: 5px;
        color: white;

    }

</style>