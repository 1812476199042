<template>
    <div>
        <AddDocumentForm @create-document-event="handleCreateDocumentEvent" :copydocumentid="copydocumentid"/>

        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Расходы</span>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn
                        color="primary"
                        depressed
                        @click="addDocument"
                >
                    Создать
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-text-field
                                label="Контрагент"
                                placeholder="Введите данные для поиска"
                                v-model="documentCustomer"
                                @keyup.enter="get"
                        ></v-text-field>
                        <v-row>
                            <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                <vc-date-picker v-model="startDocumentDate" :model-config="modelConfig">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <v-text-field
                                                label="Дата документа"
                                                placeholder="Начало"
                                                :value="inputValue"
                                                :rules="externalRules.dateRules"
                                                @click="togglePopover()"
                                                v-mask="'##.##.####'"
                                                v-model="startDocumentDate"
                                                ref="startDate"
                                        />
                                    </template>
                                </vc-date-picker>
                            </v-col>
                            <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                <vc-date-picker v-model="endDocumentDate" :model-config="modelConfig">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <v-text-field
                                                placeholder="Окончание"
                                                :value="inputValue"
                                                :rules="externalRules.dateRules"
                                                @click="togglePopover()"
                                                v-mask="'##.##.####'"
                                                v-model="endDocumentDate"
                                                ref="endDate"
                                        />
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col style="padding-top: 0; padding-bottom: 0">
                                <v-select
                                        label="Статья"
                                        v-model="accountingSelect"
                                        :items="accountingArr"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col style="padding-top: 0; padding-bottom: 0">
                                <v-select
                                        label="Направление деятельности"
                                        v-model="activitySelect"
                                        :items="activityArr"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row
                                align="center"
                                justify="space-around"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >
                    <template v-slot:item.number="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditDocument', params: { id: item.id }}">
                            {{ item.number }}
                        </router-link>
                    </template>
                    <template v-slot:item.date.date="{ item }">
                        {{ item.date.date | formatDocDate }}
                    </template>
                    <template v-slot:item.sum="{ item }">
                        {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.sum) }}
                    </template>
                    <template v-slot:item.copydocument="{ item }">
                        <v-icon
                                size="21"
                                @click="copydocument(item)"
                        >
                            mdi-content-copy
                        </v-icon>
                    </template>
                    <template slot="body.append"
                    >
                        <tr v-show="$store.state.auth.user.roles[0] == 'ROLE_SUPER_ADMIN'">
                            <th>Всего:</th>
                            <th></th>
                            <th style="text-align: left">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getDocumentsSumm())}}</th>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AddDocumentForm from "@/components/AddDocumentForm";
    import DocumentsDataService from "@/services/DocumentsDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import AccountingDataService from "@/services/AccountingDataService";
    import ActivitiesDataService from "@/services/ActivitiesDataService";

    export default {
        name: "Documents",
        components: {AddDocumentForm},
        data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },
                accountingSelect: { name: 'Любая', id: -1 },
                accountingArr: [
                    { name: 'Любая', id: -1 },
                ],
                activitySelect: { name: 'Любое', id: -1 },
                activityArr: [
                    { name: 'Любое', id: -1 },
                ],

                searchString: '', //Строка поиска
                loading: true,
                startDocumentDate: '',
                endDocumentDate: '',
                documentCustomer: '',
                copydocumentid: null, //В эту переменную передаем ID копируемого документа при копировании документа
                externalRules: ExternalRules,
                headers: [
                    {
                        text: 'Номер',
                        value: 'number',
                        width: 100
                    },
                    {
                        text: 'Дата',
                        value: 'date.date',
                        width: 100
                    },
                    {
                        text: 'Сумма',
                        value: 'sum',
                        width: 100
                    },
                    {
                        text: 'Контрагент',
                        value: 'customername',
                        width: 250
                    },
                    {
                        text: 'Статья',
                        value: 'accountingname',
                        width: 250
                    },
                    {
                        text: 'Напр.деят.',
                        value: 'activityname',
                        width: 120
                    },
                    {
                        text: 'Договор',
                        value: 'ordernumber',
                        width: 100
                    },
                    {
                        text: 'Назначение документа',
                        value: 'purposepay',
                        width: 350
                    },
                    // {
                    //     text: 'Примечания',
                    //     value: 'description',
                    // },
                    {
                        text: '',
                        value: 'copydocument',
                        width: 20
                    },
                ],
                items: [],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.startDocumentDate) {
                this.startDocumentDate = localStorage.startDocumentDate
            }
            if (localStorage.endDocumentDate) {
                this.endDocumentDate = localStorage.endDocumentDate
            }
            if (localStorage.documentCustomer) {
                this.documentCustomer = localStorage.documentCustomer
            }
            if (localStorage.accounting) {
                this.accountingSelect = JSON.parse(localStorage.getItem('accounting'))
            }
            if (localStorage.activity) {
                this.activitySelect = JSON.parse(localStorage.getItem('activity'))
            }
            //Запрашиваем статьи учета (для поиска)
            this.getAccounting()

            //Запрашиваем направления деятельности (для поиска)
            this.getActivities()

            //Запрашиваем данные с сервера
            this.get()
        },
        watch: {
            startDocumentDate(date) {
                // Вызываем  событие при изменении значения startDocumentDate - формируем последний день выбранного месяца
                if (this.externalRules.dateRulesCheck(date)) {
                    let dateObj = new Date(this.formatDateToIso(date)) //Создаем объект даты
                    const month = dateObj.getUTCMonth()
                    //const day = dateObj.getUTCDate();
                    const year = dateObj.getUTCFullYear();
                    this.endDocumentDate = new Date(year, month+1, 0).toLocaleDateString() //Обычно даты начинаются с 1, но технически возможно передать любое число, и дата сама себя поправит. Так что если передать 0, то это значение будет соответствовать «один день перед первым числом месяца», другими словами: «последнее число прошлого месяца».
                }
            }
        },
        filters: {
            formatDocDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
        },
        methods: {
            //При срабатывании события создания объекта в дочернем компоненте - перенаправляем на страницу редактирования
            handleCreateDocumentEvent(documentid) {
                this.$router.push({ name: 'EditDocument', params: { id : documentid }})
            },

            addDocument() {
                this.copydocumentid = null
                this.$store.dispatch('showAddDocumentDialog');
            },
            resetForm: function () {
                this.startDocumentDate = ''
                this.endDocumentDate = ''
                this.documentCustomer = ''
                this.accountingSelect = { name: 'Любая', id: -1 }
                this.activitySelect = { name: 'Любое', id: -1 }
            },
            get: function () {
                //Save Localstorage
                localStorage.startDocumentDate = this.startDocumentDate
                localStorage.endDocumentDate = this.endDocumentDate
                localStorage.documentCustomer = this.documentCustomer
                localStorage.setItem('accounting', JSON.stringify(this.accountingSelect))
                localStorage.setItem('activity', JSON.stringify(this.activitySelect))
                this.loading = true

                DocumentsDataService.find(this.formatDateToIso(this.startDocumentDate),this.formatDateToIso(this.endDocumentDate), this.documentCustomer, this.accountingSelect.id, this.activitySelect.id)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getAccounting() {
                AccountingDataService.get()
                    .then(response => {
                        //Добавляем в массив поиска элементы
                        response.data.forEach((item) => {
                            this.accountingArr.push(item)
                        })

                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getActivities() {
                ActivitiesDataService.find()
                    .then(response => {
                        //Добавляем в массив поиска элементы
                        response.data.forEach((item) => {
                            this.activityArr.push(item)
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },

            //Сумма в футере
            getDocumentsSumm() {
                let Summ = 0
                this.items.forEach(function (item) {
                    Summ  = Summ + Number(item.sum)
                })
                return Summ
            },
            copydocument(document) {
                this.copydocumentid = document.id
                this.$store.dispatch('showAddDocumentDialog');
            },
        },

    }
</script>
