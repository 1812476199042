export default {
    state: {
        isAddNomenclatureDialogOpen: false
    },
    actions: {
        showAddNomenclatureDialog({ commit }) {
            commit('OPEN_ADD_NOMENCLATURE_DIALOG');
        },
        closeAddNomenclatureDialog({ commit }) {
            commit('CLOSE_ADD_NOMENCLATURE_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_NOMENCLATURE_DIALOG(state) {
            state.isAddNomenclatureDialogOpen= true
        },
        CLOSE_ADD_NOMENCLATURE_DIALOG(state) {
            state.isAddNomenclatureDialogOpen = false
        }
    },

    getters: {
        isAddNomenclatureDialogOpen: state => {
            return state.isAddNomenclatureDialogOpen
        },
    }
}