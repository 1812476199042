<template>
<div>
    <div style="margin: 15px 15px;" v-if="status_id == 0">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td v-tooltip="'Ожидает выполнения'" @click="setStatus(0)" style="background-color: #479de9; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="'Завершена'" @click="setStatus(1)"  style="border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name" >Ожидает выполнения</div>
    </div>

    <div style="margin: 15px 15px;" v-if="status_id == 1">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td v-tooltip="'Ожидает выполнения'" @click="setStatus(0)" style=" background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="'Завершена'" @click="setStatus(1)" style="background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name">Завершена</div>
    </div>


</div>
</template>

<script>
     import TaskDataService from "@/services/TaskDataService";

    export default {
        props: ['statusid', 'taskid'],
        name: "TaskStepper",
        data () {
            return {
                status_id: this.statusid,
            }
        },
        computed: {
            getPermissions() {
                return this.$store.getters['auth/ability']
            }
        },
        watch: {
            //Использую слежение за входным параметром. Без этого был глюк, показывал не правильные статусы
            taskstatus: function (val) {
                this.status_id = val
            }
        },
        methods: {
            //Установка нового статуса
            setStatus(code) {
                if (this.getPermissions.can('TASK_STATUS_UPDATE', 'all')) {
                    TaskDataService.setStatus(this.taskid, code)
                        .then(response => {
                            this.status_id = code
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        }

    }
</script>

<style scoped>
    .order-stepper {
        border-spacing: 0;
        height: 9px;
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
        min-width: 130px;
        max-width: 350px;
    }

    .order-stepper td {
        border: 1px solid #ccc;
    }

    .order-stepper td:hover {
        border: 1px solid #bdb9b9;
        cursor: pointer;
    }

    .step-name {
        color: rgb(140, 143, 145);
        font-size: 11px;
        padding: 5px 0 0 0px;
        line-height: 14px !important;
        font-weight: 300;
    }

</style>