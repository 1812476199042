<template>
    <div>
        <AddNomenclatureForm @create-nomenclature-event="handleCreateNomenclatureEvent"/>

        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300"><router-link class="itemlink" :to="{ name: 'Settings'}">Настройки</router-link> -> Номенклатура</span>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn
                        color="primary"
                        depressed
                        @click="addNomenclature"
                >
                    Создать
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                    <v-text-field
                            label="Наименование"
                            placeholder="Введите данные для поиска"
                            v-model="servicename"
                            @keyup.enter="get"
                    ></v-text-field>
                        <v-row
                                align="center"
                                justify="space-around"
                                class="mt-5"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="mb-3"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                                    class="mb-3"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                >
                    <template v-slot:item.servicename="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditNomenclature', params: { id: item.serviceid }}">{{ item.servicename}}</router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ServiceViewDataService from "@/services/ServiceViewDataService";
    import AddNomenclatureForm from "@/components/AddNomenclatureForm";


    export default {
        name: "NomenclatureCatalog",
        components: {AddNomenclatureForm},
        data() {
            return {
                servicename: '', //Строка поиска

                loading: true,
                headers: [
                    {
                        text: 'Наименование',
                        value: 'servicename',
                    },
                    {
                        text: 'Единица измерения',
                        value: 'servicemeasurekod.measurefullname',
                        width: 300
                    },
                    {
                        text: 'Цена',
                        value: 'price',
                        width: 200
                    },
                ],
                items: [],
            }
        },
        mounted() {
            //read Localstorage
            if (localStorage.servicenamesearchstring) {
                this.servicename = localStorage.servicenamesearchstring
            }

            //Запрашиваем данные с сервера
            this.get()
        },

        methods: {
            //При срабатывании события создания объекта в дочернем компоненте - перенаправляем на страницу редактирования
            handleCreateNomenclatureEvent(serviceid) {
                this.$router.push({ name: 'EditNomenclature', params: { id : serviceid }})
            },

            addNomenclature() {
                this.$store.dispatch('showAddNomenclatureDialog');
            },
            resetForm: function () {
                this.servicename = ''
            },
            get: function () {
                //Save Localstorage
                localStorage.servicenamesearchstring = this.servicename

                this.loading = true

                let params = {
                    servicename: this.servicename
                }
                ServiceViewDataService.find(params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
    }
</script>

<style scoped>

</style>