<template>
  <div>


      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>


      <div>
          <v-row>
              <v-col>
                  <span style="font-size: 25px; font-weight: 300"><router-link class="itemlink" :to="{ name: 'Settings'}">Настройки</router-link> -> <router-link class="itemlink" :to="{ name: 'Nomenclature'}">Номенклатура</router-link> -> Редактирование</span>
                  <v-switch
                          class="float-right"
                          label="Активен"
                          v-model="nomenclature.enabled"
                          @change="updateNomenclature"
                  ></v-switch>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Основное</v-card-title>
                      <v-card-text>

                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                              Наименование
                            </div>
                            <div class="data-text" v-show="!isEditName">
                                <span> {{ nomenclature.servicename }} </span>
                                <v-icon
                                      @click="editName"
                                      right
                                      small
                                >
                                mdi-pencil
                              </v-icon>
                            </div>
                            <div v-show="isEditName">
                              <v-form
                                      v-model="valid"
                                      lazy-validation
                              >
                                  <v-text-field
                                          v-model="editedItem.servicename"
                                          :rules="externalRules.requiredRules"
                                          ref="servicename"
                                  ></v-text-field>
                              </v-form>
                              <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeName"
                              >
                                  Отмена
                              </v-btn>
                              <v-btn
                                      :disabled="!valid"
                                      color="blue darken-1"
                                      text
                                      @click="saveName"
                              >
                                  Сохранить
                              </v-btn>
                          </div>










<!--                                  <v-btn-->
<!--                                          color="blue darken-1"-->
<!--                                          text-->
<!--                                          @click="closeAddressInPlace"-->
<!--                                  >-->
<!--                                      Отмена-->
<!--                                  </v-btn>-->
<!--                                  <v-btn-->
<!--                                          color="blue darken-1"-->
<!--                                          text-->
<!--                                          @click="saveAddressInPlace"-->
<!--                                  >-->
<!--                                      Сохранить-->
<!--                                  </v-btn>-->

                          </div>
                        </v-row>
                        <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Единица измерения
                                  </div>
                                  <div class="data-text" v-show="!isEditMeasure">
                                      <span> {{ nomenclature.servicemeasurekod.measurefullname }} </span>
                                      <v-icon
                                              @click="editMeasure"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                  </div>
                                  <div v-show="isEditMeasure">
                                      <v-form
                                              v-model="valid"
                                              lazy-validation
                                      >
                                          <v-autocomplete
                                                  :rules="externalRules.requiredRules"
                                                  ref="measure"
                                                  v-model="measureSelect"
                                                  :items="measureItems"
                                                  item-text="measurefullname"
                                                  item-value="measureid"
                                                  return-object
                                          ></v-autocomplete>
                                      </v-form>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeMeasure"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              :disabled="!valid"
                                              color="blue darken-1"
                                              text
                                              @click="saveMeasure"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>










                                  <!--                                  <v-btn-->
                                  <!--                                          color="blue darken-1"-->
                                  <!--                                          text-->
                                  <!--                                          @click="closeAddressInPlace"-->
                                  <!--                                  >-->
                                  <!--                                      Отмена-->
                                  <!--                                  </v-btn>-->
                                  <!--                                  <v-btn-->
                                  <!--                                          color="blue darken-1"-->
                                  <!--                                          text-->
                                  <!--                                          @click="saveAddressInPlace"-->
                                  <!--                                  >-->
                                  <!--                                      Сохранить-->
                                  <!--                                  </v-btn>-->

                              </div>
                          </v-row>
                        <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Стоимость
                                  </div>
                                  <div class="data-text" v-show="!isEditPrice">
                                      <span> {{ nomenclature.price }} </span>
                                      <v-icon
                                              @click="editPrice"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                  </div>
                                  <div v-show="isEditPrice">
                                      <v-form
                                              v-model="valid"
                                              lazy-validation
                                      >
                                          <v-text-field
                                                  v-model="editedItem.price"
                                                  :rules="externalRules.requiredRules.concat(externalRules.floatRules)"
                                                  ref="serviceprice"
                                          ></v-text-field>
                                      </v-form>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closePrice"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              :disabled="!valid"
                                              color="blue darken-1"
                                              text
                                              @click="savePrice"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>










                                  <!--                                  <v-btn-->
                                  <!--                                          color="blue darken-1"-->
                                  <!--                                          text-->
                                  <!--                                          @click="closeAddressInPlace"-->
                                  <!--                                  >-->
                                  <!--                                      Отмена-->
                                  <!--                                  </v-btn>-->
                                  <!--                                  <v-btn-->
                                  <!--                                          color="blue darken-1"-->
                                  <!--                                          text-->
                                  <!--                                          @click="saveAddressInPlace"-->
                                  <!--                                  >-->
                                  <!--                                      Сохранить-->
                                  <!--                                  </v-btn>-->

                              </div>
                          </v-row>
                        <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Описание
                                  </div>
                                  <div class="data-text" v-show="!isEditDescription">
                                      <span v-if="nomenclature.servicedescription">{{ nomenclature.servicedescription }}</span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editDescription"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                  </div>
                                  <div v-show="isEditDescription">
                                      <v-form
                                              v-model="valid"
                                              lazy-validation
                                      >
                                          <v-textarea
                                                  v-model="editedItem.servicedescription"
                                                  ref="servicedescription"
                                                  rows="3"
                                          ></v-textarea>
                                      </v-form>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeDescription"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              :disabled="!valid"
                                              color="blue darken-1"
                                              text
                                              @click="saveDescription"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>






                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

              </v-col>
          </v-row>
      </div>

  </div>
</template>
<script>
    import ServiceViewDataService from "@/services/ServiceViewDataService";
    import MeasureTableDataService from "@/services/MeasureTableDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    export default {
      name: 'EditNomenclature',

      data: function() {
            return {
                nomenclature: {}, //Объект номенклатуры

                //Редактирование на месте
                isEditName: false,
                isEditDescription: false,
                isEditPrice: false,
                isEditMeasure: false,

                measureItems: null,
                measureSelect: null,

                valid: true,
                editedItem: {}, //В этой переменной сохраняем объект редактирования
                snackbarSave: false,
                externalRules: ExternalRules
            }
        },
        methods: {

            getNomenclature(id) {
                ServiceViewDataService.get(id)
                    .then(response => {
                        this.nomenclature = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            editName() {
                this.editedItem.servicename = this.nomenclature.servicename
                this.isEditName = true
                this.$nextTick(() => {
                    this.$refs.servicename.focus();
                })
            },
            closeName() {
                this.isEditName = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveName() {
                this.nomenclature.servicename = this.editedItem.servicename
                this.closeName()
                this.updateNomenclature()
            },

            editDescription() {
                this.editedItem.servicedescription = this.nomenclature.servicedescription
                this.isEditDescription = true
                this.$nextTick(() => {
                    this.$refs.servicedescription.focus();
                })
            },
            closeDescription() {
                this.isEditDescription = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveDescription() {
                this.nomenclature.servicedescription = this.editedItem.servicedescription
                this.closeDescription()
                this.updateNomenclature()
            },

            editPrice() {
                this.editedItem.price = this.nomenclature.price
                this.isEditPrice = true
                this.$nextTick(() => {
                    this.$refs.serviceprice.focus();
                })
            },
            closePrice() {
                this.isEditPrice = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            savePrice() {
                this.nomenclature.price = this.editedItem.price
                this.closePrice()
                this.updateNomenclature()
            },

            editMeasure() {
                this.getMeasureItems();
                this.isEditMeasure = true
                this.measureSelect = this.nomenclature.servicemeasurekod.measureid
                this.$nextTick(() => {
                    this.$refs.measure.focus();
                })
            },
            closeMeasure() {
                this.isEditMeasure = false
                this.measureSelect = null
                this.measureItems = null
            },
            saveMeasure() {
                this.nomenclature.servicemeasurekod = this.measureSelect
                this.closeMeasure()
                this.updateNomenclature()
            },

            updateNomenclature() {
                let data = {
                    servicename: this.nomenclature.servicename,
                    servicedescription: this.nomenclature.servicedescription,
                    servicedefaultprice: this.nomenclature.price,
                    measureid : this.nomenclature.servicemeasurekod.measureid,
                    enabled : this.nomenclature.enabled
                }
                ServiceViewDataService.update(this.nomenclature.serviceid, data)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Запрос единиц измерения
            getMeasureItems() {
                MeasureTableDataService.find()
                    .then(response => {
                        this.measureItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }


        },
        mounted() {
            this.getNomenclature(this.$route.params.id)
        },
    }
</script>
<style scoped>
.data-name .v-icon:hover {
  color: #0d47a1;
}
</style>