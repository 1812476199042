export default {
    state: {
        isAddDocumentDialogOpen: false
    },
    actions: {
        showAddDocumentDialog({ commit }) {
            commit('OPEN_ADD_DOCUMENT_DIALOG');
        },
        closeAddDocumentDialog({ commit }) {
            commit('CLOSE_ADD_DOCUMENT_DIALOG');
        }
    },
    mutations: {
        OPEN_ADD_DOCUMENT_DIALOG(state) {
            state.isAddDocumentDialogOpen= true
        },
        CLOSE_ADD_DOCUMENT_DIALOG(state) {
            state.isAddDocumentDialogOpen = false
        }
    },

    getters: {
        isAddDocumentDialogOpen: state => {
            return state.isAddDocumentDialogOpen
        },
    }
}