import http from "../http-common"
import authHeader from './auth-header';

class MeasureTableDataService {

    //Запрос (поиск) записей
    find() {
        return http.get(`/measure`, { headers: authHeader() });
    }

}

export default new MeasureTableDataService()